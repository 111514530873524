<template>
  <div id="brands-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table ref="table" :data="filteredItems" :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center mr-auto">
            <router-link v-if="$acl.check('admin')" :to="{name: 'brandCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2">{{$t('sort')}}: </span>
                <span class="capitalize mr-2">{{$t(current)}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="setCurrent('all')"><span>{{ $t('all') }}</span></vs-dropdown-item>
                <vs-dropdown-item @click="setCurrent('active')"><span>{{ $t('only_active') }}</span></vs-dropdown-item>
                <vs-dropdown-item @click="setCurrent('passive')"><span>{{ $t('only_passive') }}</span></vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * itemsPerPage - (itemsPerPage - 1) }} - {{ brands.pagination.all - currentx * itemsPerPage > 0 ? currentx * itemsPerPage : brands.pagination.all }} of {{ brands.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="setLimit(opt)">
                  <span>{{ opt }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="relative inline-block">
            <vs-input :placeholder="$t('search')" class="rounded-lg" v-model="searchQuery"></vs-input>
            <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
          </div>
        </div>
        <template slot="thead">
          <vs-th class="w-4">{{ $t('fields.image') | capitalize }}</vs-th>
          <vs-th>{{ $t('fields.code') }}</vs-th>
          <vs-th>{{ $t('fields.name_tm') }}</vs-th>
          <vs-th>{{ $t('fields.name_ru') }}</vs-th>
          <vs-th>{{ $t('fields.name_en') }}</vs-th>
          <vs-th>{{ $t('fields.active') }}?</vs-th>
          <vs-th>{{ $t('fields.published') }}?</vs-th>
          <vs-th>{{ $t('fields.items') }}</vs-th>
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3" :class="tr.isActive ? '' : 'text-danger'" :state="!tr.canPublished ? 'danger' : ''">
            <vs-td>
              <div class="img">
                <img v-if="tr.image_isAdded" :src="`${$baseUrl}/brands/${tr.uuid}.webp`" alt="logo">
                <p v-else class="text-black m-1">No image!</p>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].code">
              {{ data[indextr].code }}
            </vs-td>
            <vs-td :data="data[indextr].name_tm">
              {{ data[indextr].name_tm }}
            </vs-td>
            <vs-td :data="data[indextr].name_ru">
              {{ data[indextr].name_ru }}
            </vs-td>
            <vs-td :data="data[indextr].name_en">
              {{ data[indextr].name_en }}
            </vs-td>
            <vs-td :data="data[indextr].isActive">
              <vs-chip :color="tr.isActive ? 'primary' : 'danger'">
                <feather-icon v-if="tr.isActive" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].canPublished">
              <vs-chip :color="tr.canPublished ? 'primary' : 'danger'">
                <feather-icon v-if="tr.canPublished" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].product_count">
              {{ data[indextr].product_count }}
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EyeIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" title="View brand details" @click.stop="showData(tr.uuid)" />
              <feather-icon v-if="$acl.check('admin')" icon="EditIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="ml-2" title="Edit brand details" @click.stop="editData(tr.uuid)" />
              <!-- <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="ml-2" title="Change brand status" @click.stop="changeStatus(tr.uuid, tr.isActive)" /> -->
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
      <vs-row class="mt-4">
        <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      itemsPerPage: Number(this.$route.query.limit || 15),
      itemsOffset: Number(this.$route.query.limit || 15) * (Number(this.$route.query.page || 1) - 1),
      isLoading: true,
      currentx: Number(this.$route.query.page || 1),
      brands: { data: [], pagination: {} },
      perPageOptions: [10, 15, 20, 50, 100],
      searchQuery: this.$route.query.q || '',
      keyword: this.$route.query.q || '',
      isMounted: false,
      current: this.$route.query.current || 'all'
    }
  },
  watch: {
    current () { this.fetchData() },
    itemsPerPage () { this.fetchData() },
    searchQuery (newVal) {
      if (newVal.length > 2) {
        this.keyword = newVal
        this.currentx = 1
        const query = { ...this.$route.query, q: String(newVal) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      }
      if (!newVal) {
        this.keyword = ''
        const query = { ...this.$route.query, q: null }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      }
      this.fetchData()
    }
  },
  computed: {
    pages ()         { return Math.ceil(this.brands.pagination.all / this.itemsPerPage) },
    filteredItems () { return this.brands.data }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get('/brands', {
        params: {
          limit: this.itemsPerPage,
          offset: this.itemsOffset,
          canPublished: this.current === 'all' ? null : this.current !== 'passive',
          isActive: this.current === 'all' ? null : this.current !== 'passive',
          keyword: this.keyword
        }
      }).then(response => { this.brands = response.data })
        .catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
      this.isLoading = false
    },
    paginateTo (e) {
      if (this.isMounted) {
        this.itemsOffset = this.itemsPerPage * (this.currentx - 1)
        const query = { ...this.$route.query, page: String(e) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
        this.fetchData()
      } else {
        this.isMounted = true
      }
    },
    setCurrent (e) {
      this.current = e
      const query = { ...this.$route.query, current: e }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ query })
      }
    },
    editData (id) { this.$router.push(`/brands/${id}/edit`) },
    showData (id) { this.$router.push(`/brands/${id}`) },
    async changeStatus (id, status) {
      await this.$http.patch(`/brands/edit/${id}`, { isActive: !status }).then(() => {
        this.fetchData()
        this.notify('Success', this.$t('notify.success'), `${this.$t('brand')} ${this.$t('notify.sUpdated')}`)
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
    },
    deleteData (id) {
      this.$http.delete(`/brands/delete/${id}`).then(() => {
        this.notify('Success', this.$t('notify.success'), `${this.$t('brand')} ${this.$t('notify.sUpdated')}`)
        this.fetchData()
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
    },
    setLimit (e) {
      if (this.itemsPerPage !== e) {
        this.currentx = 1
        this.itemsOffset = 0
        this.itemsPerPage = e
        const query = { ...this.$route.query, limit: e }
        this.$router.replace({ query })
      }
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
#brands-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
  .img {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
